import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBook, faNewspaper, faLifeRing, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import styles from './style.module.css';

export default function Success(props) {
    if (!props.location?.state?.order_number) {
        // 404
    }

    return(
        <div className={styles.success}>
            <h1>Thank you</h1>

            <h2><FontAwesomeIcon icon={faCheck} color="#16bb75" />&nbsp; We have received your order</h2>
            <p>Your order number is <strong>#{props.location?.state?.order_number}</strong></p>
            <p>
                We have sent you an email confirmation.<br/>
                If the email has not arrived within 5 minutes, please check your spam folders.
            </p>

            <div className="info-banner">
                <div className="info-banner__content">
                    <FontAwesomeIcon icon={faClock} color="#0084a9" size="lg" />&nbsp; <span className="info-banner__heading">Order status</span>
                    <p className="info-banner__text">Your order is being processed by our operations team and we will notify you when your delivery has shipped.</p>
                </div>
            </div>

            <h2>Welcome to the Nanopore Community</h2>
            <p>Thank you for placing your order with Oxford Nanopore Technologies.</p>

            <div className={styles.tiles}>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faBook} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Knowledge centre</strong>
                        <br/>
                        <p>Access to protocols, guides and tutorials on using our technology.</p>
                    </div>
                </div>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faNewspaper} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Community discussions</strong>
                        <br/>
                        <p>Interact with thousands of other nanopore sequencers from around the globe.</p>
                    </div>
                </div>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faLifeRing} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Online support</strong>
                        <br/>
                        <p>Direct technical support assistance from our helpful team.</p>
                    </div>
                </div>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faDownload} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Software updates</strong>
                        <br/>
                        <p>Download latest versions of sequencing and analysis software.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
